<template>
  <div class="boxes">
    <router-link
      v-for="(box, index) of boxes"
      :key="index"
      :class="box.color || colorDefault"
      :to="box.to"
    >
      <div>
        <div v-if="box.title" class="box-title" v-html="box.title"></div>
        <div v-if="box.subTitle" class="box-sub-title" v-html="box.subTitle"></div>
      </div>
    </router-link>
  </div>
</template>
<script>

export default {
  props: {
    boxes: {
      type: Array,
      required: true
    },
  },
  setup() {
    const colorDefault= 'orange';

    return {
      colorDefault,
    }
  },
}
</script>

<style scoped>
div.boxes,
div.boxes * {
  box-sizing: border-box;
  line-height: 1em;
}
div.boxes {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-gap: 32px;
  gap: 32px;
}
div.boxes > a {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 0 10px #0000;
  position: relative;
  padding-top: 64.0625%;
  height: 0;
}
div.boxes > a > div {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
div.box-title {
  text-align: center;
  font-weight:700;
  color:white;
  font-size: 32px;
  text-shadow: 1px 1px 10px rgba(0,0,0,0.2);
}
div.box-sub-title {
  text-align: center;
  font-weight:400;
  color:white;
  font-size: 20px;
  padding-top: 10px;
  text-shadow: 1px 1px 10px rgba(0,0,0,0.2);
}
div.boxes a.orange {
  background: #ffea9f;
  background-image: linear-gradient(152deg, #ffea9f 0%, #ff8c4a 100%);
}
div.boxes a.purple {
  background: #dba5f6;
  background-image: linear-gradient(152deg, #dba5f6 0%, #bf67d1 100%);
}
div.boxes a.pink {
  background: #ffb5da;
  background-image: linear-gradient(152deg, #ffb5da 0%, #f871b4 100%);
}
div.boxes a.steel {
  background: #d3d3e8;
  background-image: linear-gradient(152deg, #d3d3e8 0%, #9c9cba 100%);
}
</style>