<template>
  <v-container>
    <app-boxes :boxes="boxes"></app-boxes>
  </v-container>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import useCore from '@/helpers/core'
import Boxes from '@/components/Boxes.vue'

export default {
  name: 'OrderDashboard',
  components: {
    'app-boxes': Boxes
  },
  setup() {
    const { callApi } = useCore();

    const boxes = ref([]);

    const onData = (referer, response) => {
      switch (referer) {
        case 'mounted':
        break;
      }
      if (response.boxes) {
        boxes.value = response.boxes;
      }
    };

    const doOnMounted = () => {
      callApi({
        method: 'get',
        path: '/cms/orders/dashboard',
      })
        .then((apiResponse) => {
          onData('mounted', apiResponse);
        })
        .catch((err) => {
          return err;
        });
    };

    onMounted(doOnMounted);

    return {
      boxes,
    }
  },
};
</script>

<style>

</style>